import React from "react";
import {useTranslation} from 'react-i18next'

export const About = (props) => {
  const {t, ready} = useTranslation();
  console.log(t)

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/close-up-jeweler-makes-silver-ring-island-bali-indonesia.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{!!ready ? t('misc.About') : "About Us"}</h2>
              <p>{t('About') && !!ready ? t('About.paragraph1') : "loading..."}</p>
              <p>{t('About') && !!ready ? t('About.paragraph2'): "loading..."}</p>
              <h3>{!!ready ? t('misc.WhyUs') : 'Why Choose Us?'}</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                  <li key={`1`}>{t('About') && !!ready ? t('About.Why1'): "loading..."}</li>
                  <li key={`2`}>{t('About') && !!ready ? t('About.Why2'): "loading..."}</li>
                  <li key={`3`}>{t('About') && !!ready ? t('About.Why3'): "loading..."}</li>

                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                  <li key={`4`}>{t('About') && !!ready ? t('About.Why4'): "loading..."}</li>
                  <li key={`5`}>{t('About') && !!ready ? t('About.Why5'): "loading..."}</li>
                  <li key={`6`}>{t('About') && !!ready ? t('About.Why6'): "loading..."}</li>
                  </ul>
                </div>
              </div>
            </div>
          {/* </div> */}
          {/* <div className="col-xs-12 col-md-12"> */}
            {/* <p>{t('About') && !!ready ? t('About.paragraph3') : 'loading...'}</p> */}
            {/* <p>{t('About') && !!ready ? t('About.paragraph4') : 'loading...'}</p> */}
            {/* <p>{t('About') && !!ready ? t('About.paragraph5') : 'loading...'}</p> */}
        </div>
        </div>
      </div>
    </div>
  );
};
