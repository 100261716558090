// import { useState } from "react";
// import emailjs from "emailjs-com";
import React from "react";
import {useTranslation} from 'react-i18next'

// const initialState = {
//   name: "",
//   email: "",
//   message: "",
// };
export const Contact = (props) => {
  const {t, ready} = useTranslation();

  // const [{ name, email, message }, setState] = useState(initialState);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setState((prevState) => ({ ...prevState, [name]: value }));
  // };
  // const clearState = () => setState({ ...initialState });

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(name, email, message);
  //   emailjs
  //     .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_USER_ID")
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         clearState();
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>
                {!!ready ? t('misc.touch') : 'Get in Touch'}

                </h2>
                <p>
                {!!ready ? t('misc.form_send_text') : 'Send'}

                </p>
              </div>
              <form name="sentMessage" validate action="https://form.taxi/s/c7akihn7" method="POST">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder={!!ready ? t('misc.form_name') : 'Name'}
                        required
                        // onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder={!!ready ? t('misc.form_email') :'Email'}
                        required
                        // onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder={!!ready ? t('misc.form_message') :'Message'}
                    required
                    // onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                {!!ready ? t('misc.form_send') : 'Send'}
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>{!!ready ? t('misc.contact') :'Contact Us'}</h3>
              <a style={{color: 'inherit'}} target="_blank" rel="noreferrer" href={props.data ? props.data.maps_url : ''}><p>
                <span>
                  <i className="fa fa-map-marker"></i> {!!ready ? t('misc.address') : 'Address'}
                </span>
                {props.data ? props.data.address : "loading"}
              </p></a>
            </div>
            <a style={{color: 'inherit'}} href={props.data ? props.data.call : ''}><div className="contact-item">
              <p>
              <span>
                <i className="fa fa-phone"></i> {!!ready ? t('misc.phone') :'Phone'}
                </span>{" "}
                {props.data ?  props.data.phone : "loading"}
              </p>
            </div></a>
            <a style={{color:'inherit'}} href={props.data ? props.data.mailto : ''}> <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> {!!ready ? t('misc.form_email') : 'Email'}
                </span>{" "}
            
                {props.data ? props.data.email : "loading"}
              </p>
            </div></a>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.whatsapp : "/"}>
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 Lotus Buffs
            {/* <a href="http://www.templatewire.com" rel="nofollow">
              TemplateWire
            </a> */}
          </p>
        </div>
      </div>
    </div>
  );
};
