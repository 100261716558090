import React from "react";
import Languageoption from './languageselector'
import {useTranslation} from 'react-i18next'
import i18next from "i18next"

export const Navigation = (props) => {
  const {t, ready} = useTranslation();

  const handleClick=(e)=>{
      i18next.changeLanguage(e.target.value)
  }
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <div>
          <img className="navbar-brand1" src={'img/bg-removed/lotus2.png'} alt={'title'} />{" "}
          <a className="navbar-brand page-scroll" href="#page-top">
          {!!ready ? t('misc.name') :'Manya Industries'}
          </a></div>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {/* <li>
              <a href="#features" className="page-scroll">
                Features
              </a>
            </li> */}
             {/* <div className="dropdown dropdown--hoverable dropdown--right">
          <a href="#" aria-haspopup="true" aria-expanded="false" role="button" className="navbar__link page-scroll">
            <svg viewBox="0 0 24 24" width="20" height="20" aria-hidden="true" className="iconLanguage_tqOs">
              <path fill="currentColor" d="M12.87 15.07l-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z"></path>
              </svg>English</a>
          <ul className="dropdown__menu">
            <li><a href="/docs/installation" target="_self" rel="noopener noreferrer" className="dropdown__link dropdown__link--active" lang="en">English</a></li>
            <li><a href="/docs/installation" target="_self" rel="noopener noreferrer" className="dropdown__link dropdown__link--active" lang="en">Hindi</a></li>
          </ul>
        </div> */}
            <li>
              <a href="#portfolio" className="page-scroll" data-toggle="collapse"
            >
                          {!!ready ? t('misc.gallery') :'Gallery'}

              </a>
            </li>
             <li>
              <a href="#services" className="page-scroll" data-toggle="collapse"
            >
          {!!ready ? t('misc.product'): 'Product Offerings'}
              
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll" 
              data-toggle="collapse"
            >
                          {!!ready ? t('misc.About') :'About Us'}

              </a>
            </li>
           
            
            {/* <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li> */}
            {/* <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li> */}
            <li>
              <a href="#contact" className="page-scroll">
              {!!ready ? t('misc.contact') :'Contact Us'}

              </a>
            </li>
            <li><div style={{display: 'flex',padding: '8px 2px',
borderRadius: 0,
margin: '9px 20px 0'}}><svg viewBox="0 0 24 24" width="20" height="20" aria-hidden="true" className="iconLanguage_tqOs">
              <path fill="currentColor" d="M12.87 15.07l-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z"></path>
              </svg><Languageoption onChange={(e)=> handleClick(e)}/></div></li>
           
          </ul>
        </div>
      </div>
    </nav>
  );
};
