const Languageoption = (props) => {
    return(
        <div>
            <select style={
    {
    backgroundColor: 'inherit',
    fontFamily: "Lato",
textTransform: 'uppercase',
color: '#555',
fontSize: '15px',
fontWeight: 400,
borderRadius: 0,
    border: 'none',
    outline: 'none',
    scrollBehavior: 'smooth'
}} onChange={props.onChange}>
                {/* <option>Select Language</option> */}
                <option value={'en'} data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1">English</option>
                <option value={'hi'} data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1">हिंदी</option>
            </select>
        </div>
    )
}
export default Languageoption;