import React from "react";
import {useTranslation} from 'react-i18next'


export const Services = (props) => {
  const {t, ready} = useTranslation();
  let data = t('Services',{ returnObjects: true })
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{!!ready ? t('misc.product') : 'Product Offerings'}</h2>
          {/* <p>
           We offer various products such as
          </p> */}
        </div>
        {/* <Trans i18nKey="Services"> */}
        <div className="row">
          { typeof data === "object" ? data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
                
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
